import React, { Component } from 'react';
import styles from './Detail.module.css';
import { MdClose } from 'react-icons/md';


class Detail extends Component {

    getURL() {
        if (this.props.project.url) {
            return (
                <h6><a rel="noreferrer" href={this.props.project.url} target="_blank">Go to Website</a></h6>
            )
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div>
                        <h1>{this.props.project.customer}</h1>
                        <h2>{this.props.project.technology}</h2>
                        {this.getURL()}

                    </div>
                    <div className={styles.icon}>
                        <MdClose onClick={this.props.onClose} />
                    </div>
                </div>

                <p>{this.props.project.description}</p>
                <img  alt="of project" src={this.props.project.projectimage} className={styles.image} />
            </div>
        );
    }
}

export default Detail;