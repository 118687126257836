import React, { Component } from 'react';
import "./App.css";
import Header from './components/Header';
import CardContainer from './components/CardContainer';
import styled, { keyframes } from 'styled-components';
import Detail from './components/Detail';
import Contact from './components/Contact';

const fadeIn = keyframes`
  from {
    transform: scale(.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.5);
    opacity: 0;
  }
`;

const CDetail = styled.div`
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:white;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => props.out ? fadeOut : fadeIn} 0.2s linear;
  transition: visibility 0.2s linear;
  overflow:hidden;
  
`

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      dataLoaded: false,
      project: {},
      projects: [],
      filteredProjects: [],

    }
  }

  componentDidMount() {
    let domain = "https://api.riedmann.rocks";
    fetch(domain + "/rr/items/projects?fields=*.*")
      .then(response => response.json())
      .then(data => {
        let projects = [];
        data.data.forEach(el => {
          let found = el.cardbackground.data.thumbnails.filter(function(item) { return item.key === 'card'; });
          let cardImage = found[0].url;

          found = el.projectimage.data.thumbnails.filter(function(item) { return item.key === 'directus-large-contain'; });
          let projectImage = found[0].url;


          let project = {
            "id": el.id,
            "technology": el.technology,
            "customer": el.customer,
            "description": el.description,
            "cardimage": cardImage,
            "projectimage": projectImage,
            "url": el.url
          }
          projects.push(project);
        });


        this.setState({
          projects: projects,
          filteredProjects: projects,
          isLoaded: true
        })
      });
  }

  onItemClick = (project) => {
    document.getElementsByTagName("body")[0].style.overflowY = 'hidden';

    this.setState({
      visible: false,
      project: project,
      showContact:false

    })
  }

  onNavigationMouseOver = (technology) => {

    let filtered = this.state.projects.filter((element) => element.technology === technology);
    this.setState({
      filteredProjects: filtered
    })
  }

  onMouseLeave = () => {
    this.setState({
      filteredProjects: this.state.projects
    })
  }

  closeDetail = () => {
    document.getElementsByTagName("body")[0].style.overflowY = 'visible';
    this.setState({
      visible: true

    })
  }


  getCardContainer() {
    if (this.state.isLoaded && this.state.showContact){
        return (<Contact/>)
    } else if (this.state.isLoaded) {
      return (<CardContainer projects={this.state.filteredProjects} onItemClick={this.onItemClick} />);
    } else {
      return (
      <div className="">
        <div className="lds-heart"><div></div></div>
      </div>);
    }
  }

  showContact =()=>{
    
    this.setState({
      showContact:!this.state.showContact
    })
  }


  render() {
    return (
      <div className="app">
        <Header mouseOver={this.onNavigationMouseOver} mouseLeave={this.onMouseLeave} onShowContact={this.showContact} />
        <div>
          {this.getCardContainer()}
        </div>
        <CDetail out={this.state.visible}>
          <Detail project={this.state.project} onClose={this.closeDetail} />
        </CDetail>
        <div className="impressum">
          <a href="https://portfolio.riedmann.rocks/impressum.html">Impressum</a>
        </div>
      </div>
    );
  }
}


