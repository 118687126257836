import React, { Component } from "react";
import styled from "styled-components";
import styles from "./Card.module.css";

const ShapeEl = styled.div`
  background: url("${(props) => props.img}") no-repeat 50% 50%/150px auto;
`;

const CardEl = styled.div`
  background-image: url("${(props) => props.image}");
  background-size: cover;
  &:hover ${ShapeEl} {
    transition: transform 200ms ease-out;
    transform: ${(props) => props.anim};
  }
`;

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardimage: this.props.card.cardimage,
      isImageLoaded: false,
    };
  }

  imageIsLoaded = () => {
    this.setState({
      isImageLoaded: true,
    });
  };

  getCard() {
    let card = this.props.card;

    let animation = "perspective(150px) rotateX(0deg) rotateY(90deg)";
    let bgImage = "./images/circle.svg";
    if (card.technology === "Sharepoint") {
      bgImage = "./images/circle.svg";
      animation = "perspective(150px) rotateX(0deg) rotateY(90deg)";
    } else if (card.technology === "Web") {
      bgImage = "./images/hexagon.svg";
      animation = "perspective(150px) rotateX(90deg) rotateY(0deg)";
    } else if (card.technology === "Mobile") {
      bgImage = "./images/hexagon.svg";
      animation = "perspective(150px) rotateX(90deg) rotateY(90deg)";
    }

    return (
      <CardEl
        onClick={() => this.props.onClick(this.props.card)}
        anim={animation}
        className={styles.container}
        image={card.cardimage}
      >
        <div className={styles.box}>
          <ShapeEl anim={animation} img={bgImage} className={styles.shape}>
            <div className={styles.textbox}>
              <div className={styles.technology}>{card.customer}</div>
              <div className={styles.customer}>{card.technology}</div>
            </div>
          </ShapeEl>
        </div>
      </CardEl>
    );
  }

  getContent = () => {
    if (this.state.isImageLoaded) {
      return this.getCard();
    } else {
      return (
        <div style={{ width: "250px", height: "250px" }}>
          laden
          <img
            alt="demo"
            width="250"
            height="250"
            src={this.props.card.cardimage}
            onLoad={() => this.imageIsLoaded()}
            style={{ display: "none" }}
          />
        </div>
      );
    }
  };
  render() {
    return <div>{this.getContent()}</div>;
  }
}

export default Card;
