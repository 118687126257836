import React, { Component } from 'react';
import ReactWordcloud from 'react-wordcloud';
import styles from './Contact.module.css';

const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: false,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [25, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 2000
};

const words = [
    {
        text: 'Website',
        value: 64,
    },
    {
        text: 'Android',
        value: 50,
    },
    {
        text: 'Sharepoint',
        value: 56,
    },
    {
        text: 'IOS',
        value: 47,
    },
    {
        text: 'React Native',
        value: 45,
    },
    {
        text: 'Flutter',
        value: 45,
    },
    {
        text: 'Umsetzung',
        value: 40,
    },
    {
        text: 'Konzeption',
        value: 52,
    },
    {
        text: 'All Inclusive Lösung',
        value: 60,
    },
    {
        text: 'Webshop',
        value: 40,
    },
    {
        text: 'Mobile App',
        value: 60,
    },
    {
        text: 'Freelancing Java/C#/Web',
        value: 40,
    },
    {
        text: 'Design',
        value: 55,
    },
]

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className={styles.container}>
                
                <div className={styles.heartcontainer}>
                    <div className={styles.ldsheart}><div></div></div>
                </div>
                <div className={styles.infotext}>
                    <ReactWordcloud words={words} options={options} />
                </div>
                <div className={styles.card}>
                    <div className={styles.cardImage} >
                        
                        <img alt="riedmann " src="https://api.riedmann.rocks/rr/assets/i30frpje8vwckw84" width="300px" />
                    </div>
                    <div className={styles.cardtext}>
                        <h2>Andreas Riedmann</h2>
                        <p className={styles.cardlist}>
                            Webfanatiker, Appcoder, Projektumsetzer, Macher, Webfreak, 
                            Tuer, Sucher, Begeisterter, Partner,
                            Tüftler, Zielfokussierter, Verlässlicher
                            
                            <ul>
                                <li>info@riedmann.rocks</li>
                                <li>0650 75 26 520</li>
                            </ul>


                        </p>
                    </div>
                </div>


            </div>
        );
    }
}

export default Contact;
<div>
    <ul>
        <li>Mag. Andreas Riedmann</li>
        <li>Studium Wirtschaftinformatik TU/UNI Wien</li>
        <li>Zertifizierter Seniorprojektmanager PMA</li>
        <li>Java Developer </li>
        <li>Mobile Development with Flutter or React Native</li>
        <li>Web-Development mit React & CMS</li>
    </ul>


</div>