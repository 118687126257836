import React, { Component } from 'react';
import Card from './Card';
import styles from './CardContainer.module.css';

class CardContainer extends Component {

    onClick = (card) => {
        this.props.onItemClick(card);
    }

    getCards() {
        let cards = [];
        this.props.projects.forEach(card => {
            cards.push(<Card key={card.id} card={card} onClick={this.onClick} />);
        });
        return cards;

    }

    render() {
        return (
            <div className={styles.container}>
                {this.getCards()}
            </div>
        );
    }
}

export default CardContainer;