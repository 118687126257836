import React, { Component } from 'react';
import styles from './Header.module.css';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showContact: true
        }
    }

    showContact() {
        this.props.onShowContact();
        this.setState({
            showContact: !this.state.showContact
        })
        
    }

    getContact() {
        if (this.state.showContact) {
            return (
                <div className={styles.company}>kontakt</div>
            )
        } else {
            return (
                <div className={styles.company}>lass es uns tun</div>
                
            )
        }
    }

    render() {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.slogan}>
                    <div className={styles.sloganContainer}>
                        <ul>
                            <li>
                                <span className={styles.small}>We love </span> design
                        </li>
                            <li>
                                <span className={styles.small}>We love </span> technology
                        </li>
                            <li>
                                <span className={styles.small}>We love </span> what we do
                        </li>
                        </ul>
                        <div onClick={() => this.showContact()}>
                            {this.getContact()}
                        </div>
                    </div>

                </div>
                <div className={styles.navigation}>

                    <div className={styles.solutions}>
                        <ul>
                            <li onClick={() => this.props.mouseOver("Sharepoint")}>Sharepoint</li>
                            <li onClick={() => this.props.mouseOver("Web")} >Web</li>
                            <li onClick={() => this.props.mouseOver("Mobile")} >Mobile</li>
                            <li onClick={() => this.props.mouseLeave()} >All</li>
                        </ul>
                    </div>
                </div>

            </div>
        );
    }
}

export default Header;